@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */
// @import "assets/bootstrap.min.scss";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import 'scss/button.scss';
@import 'scss/swiper-bundle.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
// @import "~ng-zorro-antd/ng-zorro-antd.min.css"; /* Import base styles */

body {
    background: #f9f9f9;
    font-family: 'Roboto', sans-serif!important;
    font-size: 16px!important;
    // overflow-y: hidden;
}
a {
    // color: #14a800!important;
    text-decoration: none;
}
a:hover {
    color: #28a745;
    text-decoration: none;
}

h1 {
    font-family: 'Roboto', sans-serif!important;
    font-weight: 500;
    color: #050505;
    font-size: 22px;
}
h2 {
    font-size: 20px;

}
h2::first-letter {
    text-transform: uppercase;
}
h3 {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
h4 {
    font-size: 16px;
    font-weight: 500;
}


@media (min-width: 1024px) {
    .container {
        max-width: 1024px;
    }
}











.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 50px;
    height: 50px;
    margin: 8px;
    border: 5px solid #717171;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #b6b6b6 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }